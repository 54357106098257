import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { customerLookalikes } from "@/api";
import { FileInfo } from "@/types";

const useLookalikes = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const postLookalikes = async (
    fileInfo: FileInfo | null,
    segment?: string
  ) => {
    setLoading(true);
    setError(null);

    if (!fileInfo) return setError("No file info provided");

    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await customerLookalikes(
        accessToken,
        fileInfo,
        segment
      );
      if (error) {
        setError(error.message);
      } else {
        return data;
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { postLookalikes, loading, error };
};

export default useLookalikes;
