import React from "react";
import { MapboxGeoJSONFeature } from "react-map-gl";

import { Box, Typography, Card, CardContent } from "@mui/material";
import { toPercentage } from "@/utils/common";

type SimpleOverlayProps = {
  selected?: MapboxGeoJSONFeature;
};

const SimpleOverlay: React.FC<SimpleOverlayProps> = ({ selected }) => {
  return (
    <Box sx={{ position: "relative" }}>
      {selected && (
        <Card sx={{ position: "absolute", top: 12, right: 12 }}>
          <CardContent
            sx={{ p: 2, "&:last-child": { pb: 2 }, minWidth: "150px" }}
          >
            <Typography>{`${selected?.state.level}: ${selected?.state.name}`}</Typography>
            <Typography variant="body2">{`Penetration: ${toPercentage(
              selected?.state.percentage
            )}`}</Typography>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default SimpleOverlay;
