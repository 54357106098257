import {tagCategories} from "@/constants";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Button,
  Chip,
  Grid,
  ListSubheader,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {ErrorPanel, FetchData, VariableSelection} from "@/components/atoms";
import { FileInfo, Tag } from "@/types";
import { ExportDialog } from "@/components/molecules";
import useConnect from "./use-customer-connect";
import useStyles from "./Connect.styles";
import { useTagsList } from "@/hooks/api";

interface ConnectProps {
  exportComplete: boolean;
  fileInfo: FileInfo | null;
  globalTags: Tag[];
  globalSelection: Tag[];
  onFileDownload: () => void;
  onSelectionUpdate: (tags: Tag[]) => void;
  onTagsUpdate: (tags: Tag[]) => void;
}

const Connect: React.FC<ConnectProps> = ({
  exportComplete,
  fileInfo,
  globalTags,
  globalSelection,
  onSelectionUpdate,
  onFileDownload,
  onTagsUpdate,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const styles = useStyles();
  const {
    postConnectData,
    loading: customerConnectLoading,
    error: customerConnectError,
  } = useConnect();
  const {
    getTagsData,
    loading: tagsListLoading,
    error: tagsListError,
  } = useTagsList();

  const [selection, setSelection] = useState<Tag[]>(globalSelection);
  const [tags, setTags] = useState<Tag[]>(globalTags);
  const [category, setCategory] = useState("Interests");
  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>(tagCategories);

  const allowExport = permissions.includes("postcode:export");

  useEffect(() => {
    if (globalTags.length > 0) {
      setTags(globalTags);
    } else {
      const fetchData = async () => {
        const data = await getTagsData();
        if (data) {
          onTagsUpdate(data);
        }
      };
      fetchData();
    }
    setSelection(globalSelection);
  }, [globalTags, globalSelection]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (permissions.includes('postcode:snoop')) setCategories((prevState) => [...prevState, 'Merchant Spending', 'Open Banking']);
    if (permissions.includes('postcode:mastercard')) setCategories((prevState) => [...prevState, 'Spend Categories'])
  }, [permissions]);

  const handleCategorySelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value);
  };

  const handleDelete = (tag_id: number) => {
    const newSelection = selection.filter((v) => v.tag_id !== tag_id);
    onSelectionUpdate(newSelection);
    setSelection(newSelection);
  };

  const handleCheckOpen = () => setOpen(true);
  const handleCheckClose = () => setOpen(false);

  const handleOnConfirm = async () => {
    const selectionIds = selection.map((tag) => tag.tag_id);
    const data = await postConnectData(fileInfo, selectionIds);
    if (data) {
      window.open(data.url, "_blank");
      onFileDownload();
      handleCheckClose();
    }
  };

  if (tagsListLoading) return <FetchData message="Loading tags" />;
  if (customerConnectLoading)
    return <FetchData message="Preparing data for download" />;
  if (customerConnectError || tagsListError) {
    return (
      <ErrorPanel
        error={customerConnectError || tagsListError || ""}
        errorMessage={customerConnectError ?? tagsListError ?? ""}
      />
    );
  }

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader
      key={params.key}
      component="div"
      disableSticky
      className={styles.groupHeader}
    >
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  if (exportComplete) {
    return (
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="h6"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Export Complete!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your Connect export has successfully been downloaded. Click{" "}
          <strong>Next</strong> above to export lookalikes or upload a new
          customer file.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Connect
        </Typography>
        <Typography variant="body1" gutterBottom>
          Enrich your data with our variables. Choose the variables you want to
          append (Max 10).
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          id="category-select"
          label="Categories"
          variant="filled"
          select
          value={category}
          onChange={handleCategorySelect}
          InputProps={{ className: styles.textBox, disableUnderline: true }}
        >
          {categories.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id="selection-box"
          multiple
          options={tags.filter((v) => v.tag_type === category)}
          disableCloseOnSelect
          value={selection}
          onChange={(e, value) => {
            onSelectionUpdate(value);
            setSelection(value);
          }}
          groupBy={(option) => option.tag_group}
          getOptionLabel={(option) => option.tag_name}
          getOptionDisabled={() => selection.length === 10}
          renderGroup={renderGroup}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Variable"
              variant="filled"
              placeholder="Select Variables"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                style: { borderRadius: "4px" },
                disableUnderline: true,
              }}
            />
          )}
          renderTags={() => null}
          classes={{ option: styles.listOption }}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className={styles.selectionPanel}>
          <div className={styles.rowDiv}>
            <Typography variant="body2" className={styles.selectionText}>
              Selection
            </Typography>
            <Typography variant="body2" className={styles.selectionCount}>
              ({selection.length}/10)
            </Typography>
          </div>
          {selection.map((v) => (
            <Chip
              className={styles.chip}
              key={v.tag_id}
              label={`${
                v.tag_type === "Demographics" ? v.tag_group : v.tag_type
              } = ${v.tag_name}`}
              onDelete={() => handleDelete(v.tag_id)}
              size="small"
              color="secondary"
            />
          ))}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={selection.length === 0 || !allowExport}
          onClick={handleCheckOpen}
        >
          Connect
        </Button>
      </Grid>
      <ExportDialog
        open={open}
        onConfirm={handleOnConfirm}
        onClose={handleCheckClose}
      />
    </Grid>
  );
};

export default Connect;
