import { AxiosRequestConfig } from "axios";

import { ApiError } from "@/interfaces/api";
import { Audience, SaveAudienceResult, VariableGroup } from "@/types";

import { fetchData } from "@/api/utils";

interface SaveAudienceApiResponse {
  data: Array<Audience>;
  error: ApiError | null;
}

const saveAudience = async (
  accessToken: string,
  result: SaveAudienceResult
): Promise<SaveAudienceApiResponse> => {
  const url = `${process.env.REACT_APP_API_URL}/audience`;
  const config: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: result,
  };

  return fetchData(config);
};

export default saveAudience;
