import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { ExportDialog } from "@/components/molecules";
import { ErrorPanel, FetchData } from "@/components/atoms";

import { FileInfo } from "@/types";

import useLookalikes from "./use-customer-lookalikes";

interface LookalikesProps {
  fileInfo: FileInfo | null;
  segments: string[];
  onFileDownload: () => void;
}

const Lookalikes: React.FC<LookalikesProps> = ({
  fileInfo,
  segments,
  onFileDownload,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { postLookalikes, loading, error } = useLookalikes();

  const [open, setOpen] = useState(false);
  const [segment, setSegment] = useState(segments[0]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const allowExport = permissions.includes("postcode:export");

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  const handleOnConfirm = async () => {
    const data = await postLookalikes(fileInfo, segment);
    if (data) {
      window.open(data.url, "_blank");
      onFileDownload();
      handleCheckClose();
    }
  };

  const handleCheckOpen = () => setOpen(true);

  const handleCheckClose = () => setOpen(false);

  const handleSegmentChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSegment(e.target.value);

  if (loading) return <FetchData message="Preparing data for download..." />;
  if (error) return <ErrorPanel error={error} errorMessage={error} />;
  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="h6"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Lookalikes
        </Typography>
        <Typography variant="body1" gutterBottom>
          {segment
            ? "Select a segment to calculate lookalike postcodes based on the Interests of your customers."
            : "Identify lookalike postcodes based on the Interests of your customers."}
        </Typography>
      </Grid>
      {segment && (
        <Grid item xs={3}>
          <TextField
            fullWidth
            id="segment-select"
            label="Select a segment"
            variant="filled"
            select
            value={segment}
            onChange={handleSegmentChange}
          >
            {segments.sort().map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={handleCheckOpen}
          disabled={!allowExport}
        >
          Export
        </Button>
      </Grid>
      <ExportDialog
        open={open}
        onConfirm={handleOnConfirm}
        onClose={handleCheckClose}
      />
    </Grid>
  );
};

export default Lookalikes;
