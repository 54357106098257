import "mapbox-gl/dist/mapbox-gl.css";

import { MapGradient, MapSwitch } from "@/components/atoms";
import React, { useEffect, useRef, useState } from "react";
import {
  createColorConfiguration,
  gradientFactory,
  median,
  quantile,
} from "./utils";

import { InsightResult } from "@/types";
import { MapInsight } from "@/components/molecules";
import { Typography } from "@mui/material";
import mapboxgl from "mapbox-gl";
import useStyles from "./BasicMap.styles";

interface MapProps {
  latitude: number;
  longitude: number;
  zoom: number;
  height?: string;
  width?: string;
  areaScores: InsightResult[];
  regionScores: InsightResult[];
  scores: InsightResult[];
  disableInteraction: boolean;
  displayInsights: boolean;
  displayGradient: boolean;
  displaySwitch: boolean;
}

mapboxgl.accessToken =
  "pk.eyJ1IjoibXBpbGFyY3p5a3N0YXJjb3VudCIsImEiOiJjamt5MGJ0eXAwZWR3M3dxdDU0eHVjMDlqIn0.eNbhD-xQt51obCOusjkXMA";
const MAP_STYLE =
  "mapbox://styles/mpilarczykstarcount/ckumj6ij9br3w18qjurpmruku";

const BasicMap: React.FC<MapProps> = ({
  areaScores,
  regionScores,
  scores,
  displayInsights,
  displaySwitch,
  displayGradient,
  disableInteraction = true,
  latitude,
  longitude,
  zoom,
  height = "560px",
  width = "",
}) => {
  let hoveredId: string | null | number | undefined = null;
  let selectedId: string | null | number | undefined = null;

  const styles = useStyles();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [selArea, setSelArea] = useState(null);
  const [selScore, setSelScore] = useState<number | null>(0);
  const [selPct, setSelPct] = useState<string | number | null>(0);
  const [mapLoad, setMapLoad] = useState(false);
  const [level, setLevel] = useState("postal-area");
  const [steps, setSteps] = useState<string[]>(["200", "100", "0"]);

  const topScores: InsightResult[] = ([] as InsightResult[])
    .concat(scores.filter((x) => x.tag_type !== "Demographics"))
    .sort((a, b) => (a.score < b.score ? 1 : -1));

  const topDemogs: InsightResult[] = ([] as InsightResult[])
    .concat(scores.filter((x) => x.tag_type === "Demographics"))
    .sort((a, b) => (a.score < b.score ? 1 : -1));

  const areaSource = "mapbox://adamsmithstarcount.1c7qwfks";
  const regionSource = "mapbox://mpilarczykstarcount.regionboundaries";
  const regionSourceLayer = "countries";
  const areaSourceLayer = "areas_output";

  const avgRegion =
    regionScores.map((x) => x.score).reduce((a, b) => a + b, 0) /
    regionScores.length;
  const avgArea =
    areaScores.map((x) => x.score).reduce((a, b) => a + b, 0) /
    areaScores.length;

  // Calculate the area and region gradients
  const areaGradient: (string | number)[][] = [
    [Math.floor(quantile(areaScores, 0.01) * 100), "#141938"],
    [Math.floor(quantile(areaScores, 0.25) * 100), "#223b89"],
    [Math.floor(quantile(areaScores, 0.5) * 100), "#316ba7"],
    [Math.floor(quantile(areaScores, 0.75) * 100), "#2b96c5"],
    [Math.floor(quantile(areaScores, 0.9) * 100), "#80ffff"],
  ];
  const regionGradient: (string | number)[][] = [
    [Math.floor(quantile(regionScores, 0.01) * 100), "#141938"],
    [Math.floor(quantile(regionScores, 0.25) * 100), "#223b89"],
    [Math.floor(quantile(regionScores, 0.5) * 100), "#316ba7"],
    [Math.floor(quantile(regionScores, 0.75) * 100), "#2b96c5"],
    [Math.floor(quantile(regionScores, 0.9) * 100), "#80ffff"],
  ];

  const areaSteps = [
    (
      (Math.max.apply(
        Math,
        areaScores.map((x) => x.score * 100)
      ) /
        100) *
      100
    ).toFixed(2),
    ((median(areaScores.map((x) => x.score * 100)) / 100) * 100).toFixed(2),
    (
      (Math.min.apply(
        Math,
        areaScores.map((x) => x.score * 100)
      ) /
        100) *
      100
    ).toFixed(2),
  ];

  const regionSteps = [
    (
      (Math.max.apply(
        Math,
        regionScores.map((x) => x.score * 100)
      ) /
        100) *
      100
    ).toFixed(2),
    ((median(regionScores.map((x) => x.score * 100)) / 100) * 100).toFixed(2),
    (
      (Math.min.apply(
        Math,
        regionScores.map((x) => x.score * 100)
      ) /
        100) *
      100
    ).toFixed(2),
  ];

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLDivElement,
      style: MAP_STYLE,
      center: [longitude, latitude],
      zoom,
      pitch: 0,
      touchZoomRotate: false,
      dragRotate: false,
      attributionControl: false,
      maxBounds: [
        [-20, 46],
        [30, 65],
      ],
    });
  }, []);

  // Update the map
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current?.on("load", () => {
      const areaColorConfig = createColorConfiguration(
        gradientFactory(areaGradient)
      );
      const regionColorConfig = createColorConfiguration(
        gradientFactory(regionGradient)
      );

      // Check if sources already exist before adding them
      if (!map.current?.getSource("postal-area-source")) {
        map.current?.addSource("postal-area-source", {
          type: "vector",
          url: areaSource,
        });
      }
      if (!map.current?.getSource("region-source")) {
        map.current?.addSource("region-source", {
          type: "vector",
          url: regionSource,
        });
      }

      // Add postal-area layer
      if (!map.current?.getLayer("postal-area-source")) {
        map.current?.addLayer({
          id: "postal-area",
          type: "fill",
          source: "postal-area-source",
          "source-layer": areaSourceLayer,
          layout: {
            visibility: "none",
          },
          paint: {
            "fill-color": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              "#EDBE32",
              areaColorConfig,
            ],
          },
        });

        // Initialize postal area colors
        map.current?.on("data", (e) => {
          if (e.sourceId === "postal-area-source" && e.isSourceLoaded) {
            const features = map.current?.querySourceFeatures(
              "postal-area-source",
              {
                sourceLayer: areaSourceLayer,
              }
            );

            if (features) {
              features.forEach((feature) => {
                const score = feature.properties?.score ?? -1;
                const color =
                  (areaColorConfig as [number, string][]).find(
                    (config) => score >= config[0]
                  )?.[1] || "#000000";
                map.current?.setFeatureState(
                  { source: "postal-area-source", id: feature.id },
                  { color }
                );
              });
            }
          }
        });
      }

      // Add region layer
      if (!map.current?.getLayer("region-source")) {
        map.current?.addLayer({
          id: "region",
          type: "fill",
          source: "region-source",
          "source-layer": regionSourceLayer,
          layout: {
            visibility: "visible",
          },
          paint: {
            "fill-color": [
              "case",
              ["boolean", ["feature-state", "selected"], false],
              "#EDBE32",
              regionColorConfig,
            ],
          },
        });
      }

      setSteps(areaSteps);

      if (!disableInteraction) {
        // Add zoom button
        const nav = new mapboxgl.NavigationControl({
          showCompass: false,
          visualizePitch: false,
        });
        map.current?.addControl(nav, "bottom-left");
      }

      // Add colour the polygons based on the result data
      areaScores.forEach((x) => {
        map.current?.setFeatureState(
          {
            id: x.tag_id - 113,
            source: "postal-area-source",
            sourceLayer: areaSourceLayer,
          },
          {
            // score: Math.floor(( x.score / maxArea )*100),
            score: Math.floor(x.score * 100),
            score_sum: x.score_sum,
            raw_score: x.score,
            selected: false,
            name: x.tag_name,
          }
        );
      });
      regionScores.forEach((x) => {
        map.current?.setFeatureState(
          {
            id: x.tag_id,
            source: "region-source",
            sourceLayer: regionSourceLayer,
          },
          {
            score: Math.floor(x.score * 100),
            score_sum: x.score_sum,
            raw_score: x.score,
            selected: false,
            name: x.tag_name,
          }
        );
      });

      setMapLoad(true);
    });
  }, [latitude, longitude, zoom]);

  const handleLevelChange = (
    _event: React.MouseEvent<HTMLElement>,
    newLevel: any
  ) => {
    if (map.current) {
      // Hide the current level
      map.current.setLayoutProperty(level, "visibility", "none");

      // Set the chosen level to visible
      map.current.setLayoutProperty(newLevel, "visibility", "visible");
      setLevel(newLevel);

      // Update gradient steps
      newLevel === "region" ? setSteps(regionSteps) : setSteps(areaSteps);

      // Clear current selections
      clearSelections();
    }
  };

  const clearSelections = () => {
    setSelArea(null);

    [...Array(125).keys()].forEach((i) => {
      if (map.current) {
        map.current.setFeatureState(
          { id: i, source: "postal-area-source", sourceLayer: areaSourceLayer },
          { selected: false }
        );
      }
    });
    [...Array(15).keys()].forEach((i) => {
      if (map.current) {
        map.current.setFeatureState(
          {
            id: i + 100,
            source: "region-source",
            sourceLayer: regionSourceLayer,
          },
          { selected: false }
        );
      }
    });
  };

  return (
    <div className={styles.mapArea}>
      {selArea && (
        <div className={styles.sidebar}>
          <Typography className={styles.boldText}>{selArea}</Typography>
          <Typography
            className={styles.overlayText}
          >{`Penetration: ${selPct}%`}</Typography>
          <Typography className={styles.overlayText}>
            Index: {selScore}
          </Typography>
        </div>
      )}
      {mapLoad && (
        <>
          {displayInsights && (
            <div className={styles.scorebar}>
              <MapInsight topScores={topScores} topDemographics={topDemogs} />
            </div>
          )}
          {displayGradient && (
            <div className={styles.gradient}>
              <MapGradient steps={steps} />
            </div>
          )}
          {displaySwitch && (
            <div className={styles.switch}>
              <MapSwitch level={level} onLevelChange={handleLevelChange} />
            </div>
          )}
        </>
      )}
      <div
        ref={mapContainer}
        className={styles.mapContainer}
        style={{
          height,
          width: width,
          borderRadius: 5,
        }}
      />
    </div>
  );
};

export default BasicMap;
