import { ErrorPanel, FetchData } from "@/components/atoms";
import { ExportDialog } from "@/components/molecules";
import useExportAudience from "@/components/organisms/Audience/Export/use-export-audience";
import {tagCategories, exportChannels} from "@/constants";
import { useTagsList } from "@/hooks";
import { AudienceSize, Tag } from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Button,
  Chip,
  Grid,
  ListSubheader,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";

import { useExportStyles, BadChip } from "./Export.styles";

interface ExportProps {
  audienceSize: AudienceSize | null;
  globalTags: Tag[];
  globalSelection: Tag[];
  onFileDownload: () => void;
  onSelectionUpdate: (tags: Tag[]) => void;
  onTagsUpdate: (tags: Tag[]) => void;
}

const Export: React.FC<ExportProps> = ({
  audienceSize,
  globalTags,
  globalSelection,
  onFileDownload,
  onSelectionUpdate,
  onTagsUpdate,
}) => {
  const styles = useExportStyles();
  const { getAccessTokenSilently } = useAuth0();

  const {
    getTagsData,
    loading: tagsListLoading,
    error: tagsListError,
  } = useTagsList();

  const {
    exportAudienceData,
    loading: exportLoading,
    error: exportError,
  } = useExportAudience();

  const [selection, setSelection] = useState<Array<Tag>>(globalSelection);
  const [tags, setTags] = useState<Array<Tag>>(globalTags);
  const [category, setCategory] = useState("Interests");
  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>(tagCategories);

  const allowExport = permissions.includes("postcode:export");

  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedExportChannel, setSelectedExportChannel] = useState("");


  useEffect(() => {
    if (globalTags.length > 0) {
      setTags(globalTags);
    } else {
      const fetchData = async () => {
        const data = await getTagsData();
        if (data) {
          onTagsUpdate(data);
        }
      };
      fetchData();
    }
    setSelection(globalSelection);
  }, [globalTags, globalSelection]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (permissions.includes('postcode:snoop')) setCategories((prevState) => [...prevState, 'Merchant Spending', 'Open Banking']);
    if (permissions.includes('postcode:mastercard')) setCategories((prevState) => [...prevState, 'Spend Categories'])
  }, [permissions]);

  const handleStartExport = (selectedOption: string) => {
    setSelectedExportOption(selectedOption);
    setOpen(true);
  };

  const handleCategorySelect = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCategory(e.target.value);

  const handleSelectionUpdate = (_e: React.SyntheticEvent, selection: any) =>
    onSelectionUpdate(selection);

  const handleDelete = (value: number) => () => {
    const newSelection = selection.filter((v) => v.tag_id !== value);
    onSelectionUpdate(newSelection);
  };

  const handleCheckClose = () => setOpen(false);

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader
      key={params.key}
      component="div"
      disableSticky
      className={styles.groupHeader}
    >
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  const renderExportOptions = () => {
    if (selectedExportChannel) {
      if (selectedExportChannel === "Geo") {
        return (
          <Grid item xs={12} style={{ marginTop: "2em" }}>
            <Typography variant="body1" gutterBottom>
              Choose variables to append to your export (Max 20).
            </Typography>
            <Grid xs={12} container spacing={2}>
              <Grid item xs={6} style={{ marginTop: "2em" }}>
                <TextField
                  fullWidth
                  id="category-select"
                  label="Category"
                  variant="filled"
                  select
                  value={category}
                  onChange={handleCategorySelect}
                  InputProps={{
                    className: styles.textBox,
                    disableUnderline: true,
                  }}
                >
                  {categories.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} style={{ marginTop: "2em" }}>
                <Autocomplete
                  id="selection-box"
                  multiple
                  options={tags.filter((v) => v.tag_type === category)}
                  disableCloseOnSelect
                  value={selection}
                  onChange={handleSelectionUpdate}
                  groupBy={(option) => option.tag_group}
                  getOptionLabel={(option) => option.tag_name}
                  getOptionDisabled={(_options) => selection.length === 20}
                  renderGroup={renderGroup}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Variable"
                      variant="filled"
                      placeholder="Select Variables"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "4px" },
                        disableUnderline: true,
                      }}
                    />
                  )}
                  renderTags={() => null}
                  classes={{
                    option: styles.listOption,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2em", marginRight: "1em" }}>
              <Paper elevation={0} className={styles.selectionPanel}>
                <div className={styles.rowDiv}>
                  <Typography variant="body2" className={styles.selectionText}>
                    Selection
                  </Typography>
                  <Typography
                    variant="body2"
                    className={
                      selection.length > 20
                        ? styles.selectionCountError
                        : styles.selectionCount
                    }
                  >
                    ({selection.length}/20)
                  </Typography>
                </div>
                {selection.map((v, idx) =>
                  idx < 20 ? (
                    <Chip
                      className={styles.chip}
                      key={v.tag_name + " " + idx}
                      label={
                        v.tag_type === "Demographics"
                          ? v.tag_group + " = " + v.tag_name
                          : v.tag_type + " = " + v.tag_name
                      }
                      onDelete={handleDelete(v.tag_id)}
                      size="small"
                      color="secondary"
                    />
                  ) : (
                    <BadChip
                      clickable={false}
                      className={styles.chip}
                      key={v.tag_name + " " + idx}
                      label={
                        v.tag_type === "Demographics"
                          ? v.tag_group + " = " + v.tag_name
                          : v.tag_type + " = " + v.tag_name
                      }
                      onDelete={handleDelete(v.tag_id)}
                      size="small"
                      color="secondary"
                    />
                  )
                )}
              </Paper>
            </Grid>
            <Grid
              container
              xs={12}
              justifyContent={"flex-end"}
              style={{ marginTop: "1em" }}
            >
              {exportChannels
                .filter((option) => option.name === selectedExportChannel)[0]
                .options.map((selectedOption, index) => (
                  <Grid key={index} item style={{ marginRight: "1em" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={selection.length > 20 || !allowExport}
                      onClick={() =>
                        handleStartExport(selectedOption.export_type)
                      }
                    >
                      {selectedOption.name}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid item container xs={12} spacing={0} style={{ marginTop: "2em" }}>
            <Typography variant="body1" gutterBottom>
              Select the platform you wish to export data for.
            </Typography>
            {exportChannels
              .filter((option) => option.name === selectedExportChannel)[0]
              .options.map((selectedOption, index) => (
                <Grid key={index} xs={12} container alignItems={"center"}>
                  <Grid item style={{ marginTop: "1em", minWidth: "205px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={!allowExport}
                      onClick={() =>
                        handleStartExport(selectedOption.export_type)
                      }
                    >
                      {selectedOption.name}
                    </Button>
                  </Grid>
                  <Typography variant="body2" className={styles.selectionText}>
                    {selectedOption.description}
                  </Typography>
                </Grid>
              ))}
            {selectedExportChannel === "Digital" && (
              <Grid item xs={12} style={{ marginTop: "12px" }}>
                <Typography variant="caption" color="textSecondary">
                  Starcount attributes are onboarded in Adsquare and DV360, The
                  Trade Desk, App Nexus and Amobee via Eyeota
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      }
    } else {
      return null;
    }
  };

  const handleExport = async () => {
    if (selectedExportOption && audienceSize) {
      const data = await exportAudienceData(
        selection.map((v) => v.tag_id),
        selectedExportOption,
        audienceSize
      );

      if (data) {
        window.open(data.url, "_blank");
        onFileDownload();
        handleCheckClose();
        setOpen(false);
      }
    }
  };

  if (tagsListLoading) return <FetchData message="Loading tags" />;

  if (exportLoading) return <FetchData message="Preparing data for download" />;

  if (exportError || tagsListError) {
    return (
      <ErrorPanel
        error={exportError || tagsListError || ""}
        errorMessage={exportError ?? tagsListError ?? ""}
      />
    );
  }
  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Export
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          id="export-option-select"
          label="Select a Channel"
          variant="filled"
          select
          value={selectedExportChannel}
          onChange={(e) => setSelectedExportChannel(e.target.value)}
          InputProps={{ disableUnderline: true }}
        >
          {exportChannels.map((option, index) => (
            <MenuItem key={index} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {renderExportOptions()}
      <ExportDialog
        open={open}
        onConfirm={handleExport}
        onClose={handleCheckClose}
      />
    </Grid>
  );
};

export default Export;
