import React, { useEffect, useState } from "react";
import { get, keyBy } from "lodash";

import { Grid, Icon, Typography } from "@mui/material";

import { BarChart, HorizontalBarChart } from "@/components/atoms";

import { PassionIcon } from "@/assets/icons";
import passionIcons from "@/data/passion-icons.json";

import { InsightResult } from "@/types";

import { fixedOrder } from "./utils";
import useStyles from "./MapInsight.styles";

interface MapInsightProps {
  topScores: Array<InsightResult>;
  topDemographics: Array<InsightResult>;
}

const passionIconsMap = keyBy(passionIcons, "L3 ID");

const MapInsight: React.FC<MapInsightProps> = ({
  topScores,
  topDemographics,
}) => {
  const styles = useStyles();

  const [interests, setInterests] = useState<Array<InsightResult>>([]);
  const [age, setAge] = useState<Array<InsightResult>>([]);
  const [income, setIncome] = useState<Array<InsightResult>>([]);
  const [lifeStage, setLifeStage] = useState<Array<InsightResult>>([]);

  useEffect(() => {
    const interestsScores = topScores
      .filter((x) => x.tag_type === "Interests")
      .slice(0, 9);
    const ageScores = topScores.filter(
      (topScore) => topScore.tag_group === "Age"
    );
    setInterests(interestsScores);
    setAge(
      fixedOrder(
        topDemographics.filter((x) => x.tag_group === "Age"),
        "Age"
      )
    );
    setIncome(
      fixedOrder(
        topDemographics.filter((x) => x.tag_group === "Income"),
        "Income"
      )
    );
    setLifeStage(
      topDemographics.filter((x) => x.tag_group === "Lifestage").slice(0, 3)
    );
  }, [topScores, topDemographics]);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid
        item
        xs={12}
        container
        spacing={1}
        className={styles.insightBox}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography className={styles.insightTitle}>Interests</Typography>
        </Grid>
        {interests &&
          interests.map((x, idx) => (
            <Grid item xs={4} key={`top-interest-${idx}`}>
              <div className={styles.rowDiv}>
                <Icon className={styles.icon}>
                  <PassionIcon
                    size={24}
                    id={`top-interest-${idx}`}
                    icon={get(
                      passionIconsMap,
                      [x.tag_id, "icon"],
                      "love-it-circle"
                    )}
                    color="#82e1f2"
                  />
                </Icon>
                <Typography className={styles.insightText}>
                  {x.tag_name}
                </Typography>
              </div>
            </Grid>
          ))}
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={1}
        className={styles.demogBox}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography className={styles.insightTitle}>Demographics</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.insightSection}>Age</Typography>
          {age && <BarChart width={190} height={100} data={age} />}
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.insightSection}>Income</Typography>
          {income && <BarChart width={190} height={100} data={income} />}
        </Grid>
        <Grid item xs={8}>
          <Typography className={styles.insightSection}>Life Stage</Typography>
          {lifeStage && (
            <HorizontalBarChart
              width={200}
              height={90}
              marginLeft={90}
              data={lifeStage}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MapInsight;
